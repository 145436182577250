import { Grid, Typography, Button, Box } from "@mui/material";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import Head from "next/head"; // Import Head for preloading resources
import phone from "public/assets/images/home/phone.webp";
import { appLink } from "../../../utils/Constants";
import { PLAYSTORE_BLACK } from "../../../utils/ImageExport";
import AppStarRating from "../../AppStarRating";

function Landing({ imgSrc }: { imgSrc?: any }) {
  return (
    <>
      <Head>
        {/* Preload important images */}
        <link rel="preload" href={PLAYSTORE_BLACK.src} as="image" />
        {/* Preload fonts if you have any */}
        {/* <link rel="preload" href="/path/to/font.woff2" as="font" type="font/woff2" crossorigin="anonymous" /> */}
      </Head>
      <Grid
        sx={{
          display: { xs: "block", md: "flex" },
          justifyContent: "center",
          alignItems: "center",
          minHeight: { xs: "90vh", md: "95vh", lg: "60vh" },
        }}
        style={{
          width: "100%",
          background:
            "linear-gradient(60.24deg, #08AC17 -21.65%, #4D71D0 93.65%)",
          position: "relative",
        }}
        className="Landing_WrapperGrid"
      >
        {/* CARD_WRAPPER */}

        <Grid
          sx={{
            maxWidth: "1440px",
            display: { xs: "block", md: "flex" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              display: { xs: "block", md: "flex" },
              width: { xs: "100%", md: "45%" },
              paddingTop: { xs: "22px", md: "0" },
              height: { xs: "100%", md: "80%", xl: "100%" },
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            {/* LEFT_CARD */}

            <Grid
              sx={{
                width: { xs: "100%", md: "100%" },
                height: { xs: "100%", md: "100%" },
                padding: { xs: "12% 10% 1% 10%", md: "1% 8%" },
              }}
              style={{
                color: "white",
                lineHeight: "56px",
                letterSpacing: "-1%",
                zIndex: 1,
              }}
            >
              <Typography
                fontWeight="700"
                sx={{
                  fontSize: { xs: "32px", md: "56px" },
                  margin: { xs: "0", md: "0" },
                  lineHeight: { xs: "40px", md: "72px" },
                  padding: { xs: "0", md: "0 0 48px 0" },
                }}
              >
                Sell financial products and earn real money online!
              </Typography>

              {/* BUTTON CONTAINER */}
              <Grid sx={{ display: "flex" }}>
                <Grid
                  width="fit-content"
                  sx={{
                    display: { xs: "none", md: "block" },
                    marginRight: "32px",
                  }}
                >
                  <Link href={appLink} rel="preload">
                    <Image
                      src={PLAYSTORE_BLACK}
                      alt="playstore"
                      width={120}
                      height={64}
                      priority
                    />
                  </Link>
                  <Grid sx={{ display: "flex", alignItems: "center" }}>
                    <AppStarRating Rate={4.5} />
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "default",
                        marginLeft: "4px",
                        marginTop: "5px",
                      }}
                    >
                      4.5
                    </Typography>
                  </Grid>
                  <Typography
                    variant="body2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "default",
                      marginTop: "5px",
                    }}
                  >
                    50K+ Reviews
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* RIGHT_CARD */}
          <Grid
            sx={{
              margin: { xs: "auto", md: "0" },
              justifyContent: { xs: "center", md: "none" },
              paddingTop: { xs: "0px", md: "72px" },
              height: { xs: "100%", md: "70%" },
              width: { xs: "100%", md: "45%" },
              display: "flex",
              alignItems: "end",
              position: "relative",
              paddingBottom: { xs: "0px", md: "0" },
              overflow: { xs: "hidden", md: "visible" },
            }}
          >
            <Grid sx={{ margin: "60px 0", width: "250px", height: "500px" }}>
              <Image
                src={phone}
                alt="phone"
                width={250}
                height={500}
                layout="intrinsic"
                priority
              />
              <Button
                onClick={() => {
                  window.location.href = appLink;
                }}
                sx={{
                  display: ["flex", "flex", "none"],
                  cursor: "pointer",
                }}
              >
                <Image
                  src={PLAYSTORE_BLACK}
                  alt="play-store"
                  width={120}
                  height={48}
                  style={{
                    width: "auto",
                    zIndex: "100",
                    height: "48px",
                    objectFit: "contain",
                    alignItems: "center",
                    marginLeft: "35px",
                    marginTop: "10px",
                  }}
                />
              </Button>
            </Grid>

            {/* SEMI-CIRCLE BOTTOM LEFT */}
            <Grid
              sx={{
                height: { xs: "150px", md: "309px" },
                width: { xs: "200%", md: "100%" },
                zIndex: 0,
                position: "absolute",
                left: { xs: "-200px", md: "0" },
                bottom: { xs: "0px", md: "-80px" },
                right: { xs: "-200px", md: "0" },
                margin: "auto",
                display: { xs: "none", md: "block" },
              }}
            >
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 797 314"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.1"
                  cx="398.5"
                  cy="398.5"
                  r="328.5"
                  stroke="white"
                  strokeWidth="140"
                />
              </svg>
            </Grid>

            <Grid
              sx={{
                display: { xs: "block", md: "none" },
                position: "absolute",
                bottom: "-500px",
                left: "50%",
                marginLeft: "-330px",
              }}
            >
              <svg
                width="660"
                height="712"
                viewBox="0 0 360 612"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.1"
                  cx="175.5"
                  cy="308.5"
                  r="273.5"
                  stroke="white"
                  strokeWidth="70"
                />
              </svg>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          sx={{
            display: { xs: "block", md: "none" },
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            paddingBottom: "32px",
            height: "",
          }}
        >
          {imgSrc ? (
            <Link rel="preload" href={appLink}>
              <Image
                src={imgSrc}
                alt=""
                width={120}
                height={48}
                style={{
                  width: "auto",
                  height: "48px",
                  objectFit: "contain",
                }}
                loading="lazy"
              />
            </Link>
          ) : null}

          {imgSrc === PLAYSTORE_BLACK ? (
            <Grid
              width="100%"
              textAlign={"center"}
              sx={{ display: "grid", placeContent: "center" }}
            >
              <AppStarRating Rate={4.5} />
              <Typography
                variant="body2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "default",
                  marginTop: "5px",
                  color: "white",
                }}
              >
                4.5
                <span
                  style={{
                    opacity: "0.5",
                    margin: "0px 8px",
                    fontSize: "20px",
                  }}
                >
                  •
                </span>
                50K+ Reviews
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        {/* SEMI-CIRCLE TOP RIGHT */}
        <Grid
          sx={{
            height: { xs: "100px", md: "289px" },
            width: { xs: "250px", md: "500px" },
          }}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
          }}
        >
          <svg
            width="100%"
            height="434"
            viewBox="0 0 389 434"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.1"
              cx="47.5"
              cy="92.5"
              r="281.5"
              stroke="white"
              strokeWidth="120"
            />
          </svg>
        </Grid>
      </Grid>
    </>
  );
}

export const getServerSideProps = async () => {
  const imgSrc = PLAYSTORE_BLACK;
  return { props: { imgSrc } };
};

export default Landing;
