import * as React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

export default function AppStarRating({ Rate }: { Rate: number }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        zIndex: 10,
        justifyContent: { xs: "center", md: "left" },
      }}
    >
      <Rating
        name="read-only"
        value={Rate}
        precision={0.1}
        readOnly
        emptyIcon={<StarIcon style={{ color: "white" }} fontSize="inherit" />}
      />
    </Box>
  );
}
